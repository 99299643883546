<template>
  <b-card :title="`${$t('General.Update')} ${$t('Group')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('General.ArabicName')"
                label-for="arabicName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.ArabicName')"
                  rules="required"
                >
                  <b-form-input
                    v-model="name_ar"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.ArabicName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('General.EnglishName')"
                label-for="englishName"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('General.EnglishName')"
                  rules="required"
                >
                  <b-form-input
                    v-model="name_en"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('General.EnglishName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="12">
              <b-form-group
                label="Administration"
                label-for="administration"
              >
                <v-select
                  v-model="permission_option"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :options="administration_action_option"
                  placeholder="Select Administration Module"
                />
              </b-form-group>
            </b-col> -->
            <!-- Start Administration PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="LockIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('Administration') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="administration_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End Administration PERMISSION TABLE -->

            <!-- Start Warehouse PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="DatabaseIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('Warehouse') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="warehouse_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End Warehouse PERMISSION TABLE -->

            <!-- Start CRM PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="UsersIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('CRM') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="crm_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End CRM PERMISSION TABLE -->

            <!-- Start Accounting PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="TrelloIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('Accounting') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="accounting_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End Accounting PERMISSION TABLE -->

            <!-- Start Sale PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="ShoppingCartIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('Sales') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="sale_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End Sales PERMISSION TABLE -->

            <!-- Start Purchase PERMISSION TABLE -->
            <b-col md="12">
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="FileTextIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ $t('Purchases') }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="purchase_action_option"
                >
                  <template #cell(id)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(name)="data">
                    {{ data.value }}
                  </template>
                  <template #cell(read)="data">
                    <b-form-checkbox
                      v-model="data.item.read"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(add)="data">
                    <b-form-checkbox
                      v-model="data.item.add"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(update)="data">
                    <b-form-checkbox
                      v-model="data.item.update"
                      :checked="data.value"
                    />
                  </template>
                  <template #cell(delete)="data">
                    <b-form-checkbox
                      v-model="data.item.delete"
                      :checked="data.value"
                    />
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- End Purchase PERMISSION TABLE -->
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('General.Update') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BTable, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import group from '@/service/administration/group'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { heightFade } from '@core/directives/animations'
import action from '@/service/administration/action'
import module from '@/service/administration/module'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      id: router.currentRoute.params.id,
      name_ar: '',
      name_en: '',
      required,
      action_option: [],
      administration_option: [],
      administration_action_option: [],
      warehouse_option: [],
      warehouse_action_option: [],
      crm_option: [],
      crm_action_option: [],
      accounting_option: [],
      accounting_action_option: [],
      sale_option: [],
      sale_action_option: [],
      purchase_option: [],
      purchase_action_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    await this.getApiSelect()
    this.showOverLay = true
    await group.oneAxios(this.id).then(response => {
      this.name_ar = response.data.name_ar
      this.name_en = response.data.name_en
      let actionEvent = {
        read: false,
        add: false,
        update: false,
        delete: false,
      }
      // ------------------------------ Start Administration --------------------------------
      this.administration_action_option = []
      this.administration_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.administration_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End Administration --------------------------------

      // ------------------------------ Start warehouse --------------------------------
      this.warehouse_action_option = []
      this.warehouse_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.warehouse_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End Warehouse ----------------------------------

      // ------------------------------ Start CRM --------------------------------
      this.crm_action_option = []
      this.crm_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.crm_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End CRM ----------------------------------

      // ------------------------------ Start Accounting --------------------------------
      this.accounting_action_option = []
      this.accounting_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.accounting_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End Accounting ----------------------------------

      // ------------------------------ Start Sales --------------------------------
      this.sale_action_option = []
      this.sale_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.sale_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End Sales ----------------------------------

      // ------------------------------ Start Purchase --------------------------------
      this.purchase_action_option = []
      this.purchase_option.forEach(i => {
        response.data.permissions.forEach(j => {
          if (j.module_id === i.id) {
            if (j.action_id === 1) {
              actionEvent.read = true
            }
            if (j.action_id === 2) {
              actionEvent.add = true
            }
            if (j.action_id === 3) {
              actionEvent.update = true
            }
            if (j.action_id === 4) {
              actionEvent.delete = true
            }
          }
        })
        this.purchase_action_option.push({ id: i.id, name: i.name_en, ...actionEvent })
        actionEvent = {
          read: false,
          add: false,
          update: false,
          delete: false,
        }
      })
      // ------------------------------ End Purchase ----------------------------------
      this.showError = false
    }).catch(error => {
      this.apiErrors = error
      this.showError = true
    }).finally(() => { this.showOverLay = false })
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.putApi()
        }
        this.switchLoc()
      })
    },
    async putApi() {
      this.showOverLay = true
      const user = JSON.parse(localStorage.getItem('userData'))
      this.administration_action_option.push(...this.warehouse_action_option)
      this.administration_action_option.push(...this.crm_action_option)
      this.administration_action_option.push(...this.accounting_action_option)
      this.administration_action_option.push(...this.sale_action_option)
      this.administration_action_option.push(...this.purchase_action_option)
      await group.putAxios(this.id, {
        name_ar: this.name_ar,
        name_en: this.name_en,
        administration: this.administration_action_option,
        user_id: user.id,
      }).then(() => {
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.SavedSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.SavedError'), 'danger')
      }).finally(() => { this.showOverLay = false })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await action.getAxios().then(response => {
        this.action_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(1).then(response => {
        this.administration_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(4).then(response => {
        this.warehouse_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(3).then(response => {
        this.crm_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(2).then(response => {
        this.accounting_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(5).then(response => {
        this.sale_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await module.searchAxios(6).then(response => {
        this.purchase_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>
